<template>
	<div>
		<CCard class="p-4"> 
			<CCardHeader>
				<CRow>
			
					<CCol lg="6">
						<h5>Update Profile</h5>
					</CCol>
			     	<!-- <CCol lg="6">
			      		<div style="text-align: right">
			      			<CButton color="success">DR's</CButton> &nbsp; 
			      			<CButton color="info" @click="showHistoryModal = true">History</CButton> &nbsp; 
	      					<CButton color="warning">Print</CButton> &nbsp; 
			      			<CButton color="danger" @click="backToTable()">Cancel</CButton> &nbsp; 
			      		</div>
					</CCol> -->
				</CRow>
			</CCardHeader>
			<CCardBody> 
				<CRow>
					<CCol lg="3">
					</CCol>
					<CCol lg="6">
						<CCard class="p-3 pl-3 pr-3 ml-3 mr-3 mt-2"> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<div style="height: 200px; width: 200px; background-color: #eee; margin-left: auto; margin-right: auto; border-radius: 100px"></div>
								</CCol> 
							</CRow> 
							<br/>
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<CInput 
					                  label="Firstname"
					                  placeholder="Enter firstname"
					                  v-model="dataParams.firstname" 
									 
					                />
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<CInput 
					                  label="Lastname"
					                  placeholder="Enter lastname"
					                  v-model="dataParams.lastname" 
					                />
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<CInput 
					                  label="Username"
					                  placeholder="Enter username"
					                  v-model="dataParams.username" 
					                />
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<CInput 
					                  label="Email"
					                  placeholder="Enter email"
					                  v-model="dataParams.email" 
					                  required
					                />
								</CCol> 
							</CRow> 
							<!-- <CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<div class="form-group"> 
										<label>User type</label>
										<v-select 
											label="setting_name" 
											:options="usertypeList.data"
											:reduce="item => item.id"
											v-model="dataParams.usertype_id"
											placeholder="Select usertype" 
										>
											<template #search="{attributes, events}">
												<input
													class="vs__search"
													:required="!dataParams.usertype_id"
													v-bind="attributes"
													v-on="events"
												/>
											</template>
										</v-select>
									</div>
								</CCol> 
							</CRow>  -->
							<!-- <CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<div class="form-group"> 
										<label>Depot</label>
										<v-select 
											label="setting_name" 
											:options="depotList.data"
											:reduce="item => item.id"
											v-model="dataParams.depot_id"
											placeholder="Select depot" 
										>
											<template #search="{attributes, events}">
												<input
													class="vs__search"
													:required="!dataParams.depot_id"
													v-bind="attributes"
													v-on="events"
												/>
											</template>
										</v-select>
									</div>
								</CCol> 
							</CRow>  -->
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<div class="form-group">
										<label for="asset_type">Status
										</label> 
										<CInputRadioGroup  
						                    :options="[
						                    	{ 
													value: 'active', 
													label: 'Active'
										        },
										        { 
													value: 'inactive', 
													label: 'Inactive'
										        }
						                    ]" 
						                    :checked.sync="dataParams.status" 
						                    :inline="true" 
						                    required="true"
						                />
						            </div>
								</CCol> 
							</CRow>  
							<br/> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="6">
									<CButton @click="submit" color="info" style="width: 100%">Save</CButton>
								</CCol> 
								<CCol lg="6">
									<CButton to="/account/profile" color="danger" style="width: 100%"  >Cancel</CButton>
								</CCol> 
							</CRow> 
							  
						</CCard>
					</CCol>
				</CRow>
				
			</CCardBody> 
		</CCard>
		<!-- <HistoryModal :details="dataParams" @showModal="showHistoryModal = $event" :showModal="showHistoryModal" /> -->
	</div>
</template> 

<script>
import axios from '../../axios';
import config from '../../config.js';
import vSelect from 'vue-select'
import '../../style.css';
import 'vue-select/dist/vue-select.css';
import Swal from 'sweetalert2';
export default{
	
	data(){
		return{
			dataParams: {
				username: "",
				email: "",
				usertype: {
					setting_name: ""
				},
				depot: {
					setting_name: ""
				},
				status: "",
				firstname: "",
				lastname: "", 
			},
			depotList: {
				data: []
			},
			usertypeList: {
				data: []
			}
		}
	},
	mounted(){
		this.getUser();
		this.getUsertypeList();
		this.getDepotList();
	},
	components: {vSelect},
	methods: {
		getUser(){
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user);
			axios.get(config.api_path+"/users/"+user.id)
			.then(response=>{
				this.dataParams = response.data.data
			})
		},
		getUsertypeList(){
			axios.get(config.api_path+"/setting?setting_type=user_type&page=1&limit=10000000")
			.then(response=>{
				this.usertypeList = response.data
			})
		},
		getDepotList(){
			axios.get(config.api_path+"/setting?setting_type=depot&page=1&limit=10000000")
			.then(response=>{
				this.depotList = response.data
			})
		},
		submit(){
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user);
			axios.put(config.api_path+"/users/"+user.id, this.dataParams)
			.then(response=>{
				Swal.fire({
					title: 'Success!',
					text: "Profile updated successfully",
					icon: 'success', 
				})
				this.$router.push('/account/profile');
			})
		}
	}

}
</script>